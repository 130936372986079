/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"

import PropTypes from "prop-types"

import { StaticQuery, graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import { TransitionProvider, TransitionViews } from "gatsby-plugin-transitions";

import "../styles/scottluxford.less"

const Layout = ({ location, children }) => (
  <>
  <Helmet>
  	{` <!-- Google tag (gtag.js) -->`}
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-LHXXYLRB78"></script>
    <script>
      {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-LHXXYLRB78');
      `}
    </script>
  </Helmet>
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <TransitionProvider
            location={location}
            mode="successive"
            enter={{
                opacity: 0,
                config: {
                    mass: 1,
                    tension: 210,
                    friction: 20,
                    clamp: true
                }
            }}
            usual={{
                opacity: 1
            }}
            leave={{
                opacity: 0,
                config: {
                    mass: 1,
                    tension: 210,
                    friction: 20,
                    clamp: true
                }
            }}
        >
          <TransitionViews>
            {children}
          </TransitionViews>
        </TransitionProvider>
      </>
    )}
  />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
